export const banques = [
  {
    id: 1,
    name: "atb",
    image: "atb.png",
    width: "100px",
  },
  {
    id: 2,
    name: "abc",
    image: "abc.png",
    width: "200px",
  },
  {
    id: 3,
    name: "btk",
    image: "btk.png",
    width: "100px",
  },
  {
    id: 4,
    name: "stb",
    image: "stb.png",
    width: "200px",
  },
  {
    id: 4,
    name: "zitouna",
    image: "zitouna.png",
    width: "200px",
  },
  {
    id: 5,
    name: "attijari",
    image: "attijari.png",
    width: "200px",
  },
  {
    id: 5,
    name: "BIAT",
    image: "biat.png",
    width: "150px",
  },
];
