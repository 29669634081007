import {
  Button,
  Divider,
  Input,
  InputNumber,
  InputNumberProps,
  Select,
} from "antd";
import Title from "antd/es/typography/Title";
import { join } from "path";
import { useEffect, useRef, useState } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";

type Props = {
  elements: any[];
  keys?: any[];
  active: string;
};
const Correspondance = ({ elements, active }: Props) => {
  const [mapping, setMapping] = useState<any[]>([]);
  const [mappedData, setMappedData] = useState<any[]>([]);
  const [compteCredit, setCompteCredit] = useState<string>("");
  const [compteDebit, setCompteDebit] = useState<string>("");
  const [numLigne, setNumLigne] = useState<any>(0);
  const [numPiece, setNumPiece] = useState<any>(0);
  const [code, setCodeJournal] = useState<any>("");

  const specifications = [
    {
      banque: "atb",
      attributes: [
        { name: "ESCOMPTE EFFET", type: "C", value: 411100 },
        { name: "Commission", type: "D", value: 627000 },
        { name: "TVA", type: "D", value: 436600 },
        { name: "PAIEMENT EFFET", type: "D", value: 401100 },
        { name: "Cheque preavise-PREAVIS EMIS", type: "C", value: 461000 },
        { name: "Cheque preavise-UNIFACTOR", type: "C", value: 461000 },
        { name: "Encaissement CHQ", type: "C", value: 411100 },
        { name: "Deblocage MPG caution fiscale", type: "C", value: 461000 },
        { name: "Versement Especes", type: "C", value: 461000 },
        { name: "Virement Recu", type: "C", value: 461000 },
      ],
    },
    {
      banque: "btk",
      attributes: [
        { name: "COM ET TVA", type: "D", value: 627000 },
        { name: "PAIEMENT CHQ", type: "D", value: 401100 },
        { name: "VIREMENT EMIS", type: "D", value: 425000 },
        { name: "ENCAISSEMENT CHQ", type: "C", value: 411100 },
        { name: "VIR RECU", type: "C", value: 411100 },
      ],
    },
    {
      banque: "abc",
      attributes: [
        { name: "COMMISSION", type: "D", value: 627000 },
        { name: "ENCAISSEMENT CHQ", type: "C", value: 411100 },
        { name: "TAXE SUR LA VALEUR AJOUTE", type: "D", value: 436600 },
      ],
    },
    {
      banque: "BIAT",
      attributes: [
        { name: "COMMISSION", type: "D", value: 627000 },
        { name: "COM TVA/", type: "D", value: 436600 },
        { name: "TAXE SUR LA VALEUR AJOUTE", type: "D", value: 436600 },
      ],
    },
    {
      banque: "zitouna",
      attributes: [
        { name: "COM", type: "D", value: 627000 },
        { name: "ENC CHEQ", type: "C", value: 411100 },
        { name: "FRAIS PDL", type: "D", value: 627000 },
        { name: "TVA", type: "D", value: 436600 },
        { name: "PAIEMENT PROFIT", type: "D", value: 651100 },
      ],
    },
    {
      banque: "stb",
      attributes: [
        { name: "COM. ENC CHQ DINARS AUT", type: "D", value: 627000 },
        { name: "FRAIS TELECOMPENSATION", type: "D", value: 627000 },
        { name: "ENC EFFET EFF", type: "C", value: 411100 },
        { name: "ENC CHEQ", type: "C", value: 411100 },
      ],
    },
  ];

  const stringToFloat = (input: any): number => {
    // Remove commas and parse to float
    if (!isNaN(input)) return input;
    const normalized = input
      .replace(/\./g, "")
      .replace(" ", "")
      .replace(",", ".");
    return parseFloat(normalized);
  };

  const elementsBanque = [
    "Num Pièce",
    "Num Ligne",
    "Compte",
    "Contrepartie",
    "Libellé",
    "Sens",
    "Mnt Origine",
    "Mnt USD",
    "Code Jrl",
    "Date Opér.",
    "Num Facture",
  ];
  const tableRef = useRef(null);

  const updateMapping = (key: number, isDefault: boolean, value: string) => {
    const list = [...mapping];
    list[key].isDefault = isDefault;
    list[key].value = value;
    setMapping(list);
  };

  const getValue = (x: string, element: any): string => {
    const searched = mapping.filter((el) => el.name === x)[0];
    return searched.isDefault ? searched.value : element[searched.value];
  };

  const toGetCompteNumber = (libelle: any, sens: string) => {
    var json = {
      debit: compteDebit,
      credit: compteCredit,
    };
    const searched = specifications.filter((el) => el.banque == active)[0];
    var found = false;
    searched?.attributes.map((el) => {
      if (!found) {
        if (libelle.toUpperCase().includes(el.name.toUpperCase())) {
          found = true;
          if (el.type === "D") {
            json.debit = el.value + "";
          } else {
            json.credit = el.value + "";
            json.debit = compteCredit;
          }
        } else if (sens === "C") {
          json.debit = compteCredit;
          json.credit = compteDebit;
        }
      }
      // else if (libelle.toUpperCase().includes("VIREMENT")) {
      //   json.debit = compteCredit;
      //   json.credit = compteDebit;
      // }
    });
    return json;
  };

  const prepareData = () => {
    const data: any[] = [];
    var piece = numPiece;
    var ligne = numLigne;
    elements.forEach((element) => {
      var creditDebit = toGetCompteNumber(
        element["Libellé"],
        element["Débit"] != "" ? "D" : "C"
      );
      var json = {
        "Num Pièce": piece,
        "Num Ligne": ligne,
        Compte: element["Débit"] != "" ? creditDebit.debit : creditDebit.credit,
        Contrepartie: "",
        Libellé: element["Libellé"],
        Sens: element["Débit"] != "" ? "D" : "C",
        "Mnt Origine":
          element["Débit"] != ""
            ? stringToFloat(element["Débit"])
            : stringToFloat(element["Crédit"]),
        "Mnt USD": "",
        "Code Jrl": code,
        "Date Opér.": element["Date"],
        "Num Facture": "",
      };
      var json2 = {
        "Num Pièce": piece,
        "Num Ligne": ligne + 1,
        Compte: element["Débit"] == "" ? creditDebit.debit : creditDebit.credit,
        Contrepartie: "",
        Libellé: element["Libellé"],
        Sens: element["Débit"] == "" ? "D" : "C",
        "Mnt Origine":
          element["Débit"] != ""
            ? stringToFloat(element["Débit"])
            : stringToFloat(element["Crédit"]),
        "Mnt USD": "",
        "Code Jrl": code,
        "Date Opér.": element["Date"],
        "Num Facture": "",
      };
      if (json["Sens"] != "D") {
        data.push(json);
        data.push(json2);
      } else {
        data.push(json2);
        data.push(json);
      }
      piece++;
    });
    setMappedData(data);
  };

  const generateFile = () => {
    var mappedData: any[] = [];
    let numL = 2;

    elements.forEach((el: any) => {
      var json: any = {};
      elementsBanque.forEach((x) => {
        if (x == "Num Pièce") {
          json[x] = numL;
          numL++;
        } else {
          json[x] = getValue(x, el);
        }
      });
      mappedData.push(json);
    });
    setMappedData(mappedData);
  };

  useEffect(() => {
    const list: any[] = [];
    elementsBanque.map((el) => {
      list.push({ name: el, value: "", isDefault: true });
    });
    setMapping(list);
  }, []);

  const onChange: InputNumberProps["onChange"] = (value) => {
    setNumLigne(value ?? 0);
  };

  const onChangeP: InputNumberProps["onChange"] = (value) => {
    setNumPiece(value);
  };

  return (
    <>
      <Title level={3}>Correspondance</Title>
      <table>
        <tr>
          <td style={{ width: "200px" }}>Compte Crédit</td>
          <td style={{ width: "200px" }}>
            <Input onChange={(e) => setCompteCredit(e.target.value)} />
          </td>
        </tr>
        <tr>
          <td style={{ width: "200px" }}>Compte Débit</td>
          <td style={{ width: "200px" }}>
            <Input onChange={(e) => setCompteDebit(e.target.value)} />
          </td>
        </tr>
        <tr>
          <td style={{ width: "200px" }}>Num de Pièce</td>
          <td style={{ width: "200px" }}>
            <InputNumber onChange={onChangeP} />
          </td>
        </tr>
        <tr>
          <td style={{ width: "200px" }}>Num de ligne</td>
          <td style={{ width: "200px" }}>
            <InputNumber onChange={onChange} />
          </td>
        </tr>
        <tr>
          <td style={{ width: "200px" }}>Code journal</td>
          <td style={{ width: "200px" }}>
            <Input onChange={(e) => setCodeJournal(e.target.value)} />
          </td>
        </tr>
        <tr>
          <Divider />
        </tr>

        <tr>
          <th>Valeur fichier destination</th>
          <th>Valeur fichier source</th>
          <th>Fixe value</th>
        </tr>
        {/* {elementsBanque.map((el: any, key: number) => (
          <tr>
            <td style={{ width: "200px" }}>{el}</td>
            <td style={{ width: "200px" }}>
              <Select style={{ width: "200px" }} onChange={(e) => updateMapping(key, false, e)}>
                {keys.map((x) => (
                  <Select.Option value={x}>{x}</Select.Option>
                ))}
              </Select>
            </td>
            <td>
              <Input onChange={(e) => updateMapping(key, true, e.target.value)} />
            </td>
          </tr>
        ))} */}
      </table>
      <div style={{ marginTop: "20px" }}>
        <Button onClick={() => prepareData()}>Générer le fichier</Button>
        <DownloadTableExcel
          filename="table"
          sheet="table"
          currentTableRef={tableRef.current}
        >
          <Button type="primary" disabled={mappedData.length === 0}>
            Exporter le résultat
          </Button>
        </DownloadTableExcel>

        <table ref={tableRef} style={{ marginTop: "20px" }}>
          <tbody>
            {mappedData.length > 0 && (
              <>
                <tr>
                  {elementsBanque.map((el, key) => (
                    <th style={{ width: "200px" }}>{el}</th>
                  ))}
                </tr>
                {mappedData.map((line, lineKey) => (
                  <tr>
                    {elementsBanque.map((el, key) => (
                      <td style={{ width: "200px" }}>{line[el]}</td>
                    ))}
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Correspondance;
